import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Image } from '../../components/Image';

const CoachList = ({
  serviceType,
  data,
  handleListAll,
  showAll,
  loading,
}: any) => {
  const navigate = useNavigate();
  const { t } = useTranslation('common');

  return (
    <>
      <h1 className="font-bold font-satoshi rtl:font-Almarai text-2xl pb-6">
        {' '}
        {t(`SEARCH_RESULT_VIEW.${serviceType.toUpperCase()}`)}
      </h1>
      <div className="grid md:grid-cols-3  gap-4">
        {data && data.length
          ? data?.map((item: any, index: number) => (
              <div
                key={index}
                className="flex flex-col shadow-lg rounded-lg cursor-pointer "
                onClick={
                  () => navigate(`/coach-profile-view?id=${item?.id}`)
                  // navigate(
                  //   `/client/session-view?id=${
                  //     item.id
                  //   }&serviceType=${serviceType.toUpperCase()}`
                  // )
                }
              >
                <div style={{backgroundPosition: 'center'}}>
                <Image
                  id={
                    (item?.imgId as number) ||
                    (item?.imageId as number) ||
                    (item?.profilePicId as number)
                  }
                  bgImgUrl={'/assets/default-img-icon.svg'}
                  bgClass={
                    ' bg-[#F6F8F5] object-fill  flex justify-center items-center h-[165px]'
                  }
                  propClass={'image-inside-container object-fill rounded-t h-40'}
                />
                  </div>
                <div className="p-3">
                  <div className="font-bold text-darkGrey text-2xl ">
                    {item.fullName}
                  </div>
                  <div className="text-[12px] font-xs leading-4 text-gray  pb-1">
                    {item.bioDescription
                      ? item.bioDescription
                      : `--${t('NA')}--`}
                  </div>
                  <div className="flex flex-row justify-start items-start pb-1">
                    <span className="text-closeRed font-semibold text-xs pr-1">
                      {/* {item.rating} */}
                    </span>
                    {/* <Rating value={item.rating} /> */}
                  </div>
                  <div className="flex bottom-0 text-[12px] font-xs text-gray pt-6 pb-3 gap-1 items-center">
                    <div className="flex items-center pr-2 ">
                      <img
                        className="w-[26px]"
                        src="/assets/bodyAfterLogin/coach-grp-icon.svg "
                        alt=""
                      />
                    </div>
                    <div className="flex  items-center font-satoshi rtl:font-Almarai">
                      {item?.clientCount} {t('PREFORMANCE.CLIENTS')}
                    </div>
                  </div>
                </div>
              </div>
            ))
          : null}
      </div>
      {data && data.length ? (
        <div className="flex justify-center pt-10 pb-10">
          {showAll && (
            <button
              className="md_max:absolute md_max:bottom-0 md_max:left-[15vw] md_max:right-[15vw]  items-center text-16 bg-footerBg text-white font-bold font-satoshi rtl:font-Almarai py-3 px-7 border border-solid rounded"
              onClick={() => handleListAll(serviceType)}
            >
              {t(`SEARCH_RESULT_VIEW.SEE_ALL_${serviceType.toUpperCase()}`)}{' '}
            </button>
          )}
        </div>
      ) : loading ? null : null}
    </>
  );
};

export default CoachList;
