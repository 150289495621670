import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Image } from '../../components/Image';
import Tooltip from '../../components/tooltip/input';
import { Bestseller } from '../bestSeller';
export const SearchContent = ({
  serviceType,
  data,
  handleListAll,
  showAll,
  isLoading,
}: any) => {
  const navigate = useNavigate();
  const { t } = useTranslation('common');
  const divRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const priceWidthRef = useRef<HTMLDivElement>(null);
  const priceParentWidthRef = useRef<HTMLDivElement>(null);
  const [divWidth, setDivWidth] = useState<number | null>(null);
  const [containerWidth, setContainerWidth] = useState<number | null>(null);
  const [priceWidth, setPriceWidth] = useState<number | null>(null);
  const [priceParentWidth, setPriceParentWidth] = useState<number | null>(null);

  useEffect(() => {
    if (divRef.current) {
      const handleResize = () => {
        setDivWidth(divRef.current && divRef.current.clientWidth);
      };

      handleResize(); // Initial measurement
      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);
  useEffect(() => {
    if (containerRef.current) {
      const handleContainersize = () => {
        setContainerWidth(
          containerRef.current && containerRef.current.clientWidth
        );
      };

      handleContainersize(); // Initial measurement
      window.addEventListener('resize', handleContainersize);

      return () => {
        window.removeEventListener('resize', handleContainersize);
      };
    }
  }, []);
  useEffect(() => {
    if (priceWidthRef.current) {
      const handlepriceWidthRef = () => {
        setPriceWidth(
          priceWidthRef.current && priceWidthRef.current.clientWidth
        );
      };

      handlepriceWidthRef(); // Initial measurement
      window.addEventListener('resize', handlepriceWidthRef);

      return () => {
        window.removeEventListener('resize', handlepriceWidthRef);
      };
    }
  }, []);
  useEffect(() => {
    if (priceParentWidthRef.current) {
      const handlepriceParentWidth = () => {
        setPriceParentWidth(
          priceParentWidthRef.current && priceParentWidthRef.current.clientWidth
        );
      };

      handlepriceParentWidth(); // Initial measurement
      window.addEventListener('resize', handlepriceParentWidth);

      return () => {
        window.removeEventListener('resize', handlepriceParentWidth);
      };
    }
  }, []);
  return (
    <>
      <h1 className="font-bold font-satoshi rtl:font-Almarai text-2xl pb-6">
        {t(`SEARCH_RESULT_VIEW.${serviceType.toUpperCase()}`)}
      </h1>
      <div
        className={`${!isLoading && 'hidden'} grid md:grid-cols-3  gap-4 mb-10`}
      >
        {[1, 2, 3, 4, 5, 6].map((item: any, index: number) => (
          <div
            key={index}
            className="flex flex-col shadow-lg rounded-lg cursor-pointer "
          >
            <div
              className={` ${
                isLoading && 'loader_pulse w-full h-[165px] rounded'
              }`}
            ></div>

            <div className="p-3">
              <div
                className={` ${
                  isLoading && 'loader_pulse w-[50%] h-8  rounded'
                }`}
              ></div>
              <div
                className={` ${
                  isLoading && 'loader_pulse w-[30%] h-4 rounded mt-1'
                }`}
              ></div>
              <div
                className={` ${
                  isLoading && 'loader_pulse w-[65%] h-4 rounded mt-1'
                }`}
              ></div>
              <div
                className={` ${
                  isLoading && 'loader_pulse w-[75%] h-4 rounded mt-1'
                }`}
              ></div>
              <div
                className={` ${
                  isLoading && 'loader_pulse w-[65%] h-4 rounded mt-1'
                }`}
              ></div>

              <div className="flex justify-between">
                <div
                  className={` ${
                    isLoading && 'loader_pulse w-[20%] h-6 rounded mt-1'
                  }`}
                ></div>

                <div
                  className={` ${
                    isLoading && 'loader_pulse w-[30%] h-8 rounded mt-1'
                  }`}
                >
                  {' '}
                </div>
              </div>
            </div>
          </div>
        ))}{' '}
      </div>
      <div className="grid md:grid-cols-3  gap-4">
        {data && data.length && !isLoading
          ? data?.map((item: any, index: number) => (
              <div
                ref={containerRef}
                key={index}
                className="flex flex-col shadow-lg rounded-lg cursor-pointer "
                onClick={() =>
                  navigate(
                    `/service-view?id=${
                      item.id
                    }&serviceType=${serviceType.toUpperCase()}`
                  )
                }
              >
                {/* <img
                  src={
                    !item.image
                      ? '/assets/profile/profile-pic.svg'
                      : `data:image/png;base64,${item.image}`
                  }
                  alt=""
                /> */}
                <div>
                  <div style={{ backgroundPosition: 'center'}}>
                    <Image
                        id={(item?.imgId as number) || (item?.imageId as number)}
                        bgImgUrl={'/assets/default-img-icon.svg'}
                        bgClass={
                          ' bg-[#F6F8F5] object-fill rounded-t-lg flex justify-center items-center h-[165px]'
                        }
                        propClass={' image-inside-container object-fill rounded-t h-40'}
                    />
                  </div>
                  </div>
                  <div className="p-3">
                    {(divWidth as number) > (containerWidth as number) ? (
                        <Tooltip
                            content={item.title}
                            children={
                              <div
                                  ref={divRef}
                                  className={` relative font-bold text-darkGrey text-2xl w-full truncate `}
                              >
                                {item.title}
                              </div>
                            }
                        />
                    ) : (
                        <div
                            className={` relative font-bold text-darkGrey text-2xl w-full truncate `}
                        >
                          {item.title}
                        </div>
                    )}
                    <div className="text-[12px] font-xs leading-4 text-gray  pb-1">
                      {item.subTitle}
                    </div>
                    <div className="text-[12px] font-bold leading-4  text-darkGrey   pb-1">
                      {item.coachName}
                    </div>
                    {/* <div className="flex flex-row justify-start items-start pb-1">
                    <span className="text-closeRed font-semibold text-xs pr-1">
                      {item.rating}
                    </span>
                    <Rating value={item.rating} />
                    <span className="text-[12px] font-xs leading-4 text-gray  pb-1">
                      ({item.ratingCount})<span className="pl-1">ratings</span>
                    </span>
                  </div> */}
                    <div className="text-[12px] font-xs text-gray  pb-1 ">
                      {item?.duration
                          ? `${item?.duration} ${t('MINS')}`
                          : serviceType === 'SESSION'
                              ? `- ${t('MINS')}`
                              : ''}
                    </div>

                    <div className=" relative flex justify-between gap-2 ">
                      <div
                          ref={priceParentWidthRef}
                          className="w-[calc(100%-100px)] "
                      >
                        {(priceWidth as number) > (priceParentWidth as number) ? (
                            <Tooltip
                                content={`${t('SAR')} ${item.price}`}
                                children={
                                  <div
                                      ref={priceWidthRef}
                                      className="font-bold text-2xl rtl:font-Almarai  w-full truncate"
                                  >
                                    {t('SAR')}&nbsp;{item.price}
                                  </div>
                                }
                            />
                        ) : (
                            <div className="font-bold text-2xl rtl:font-Almarai  w-full truncate">
                              {t('SAR')}&nbsp;{item.price}
                            </div>
                        )}
                      </div>

                      <div className="">
                        <Bestseller/>
                      </div>
                    </div>
                  </div>
                </div>
                ))
                : null}
              </div>
            {data && data.length ? (
                <div className="flex justify-center pt-10 pb-10">
          {showAll && (
            <button
              className="md_max:absolute md_max:bottom-0 md_max:left-[15vw] md_max:right-[15vw]  items-center text-16 bg-footerBg text-white font-bold font-satoshi rtl:font-Almarai py-3 px-7 border border-solid rounded "
              onClick={() => handleListAll(serviceType)}
            >
              {t(`SEARCH_RESULT_VIEW.SEE_ALL_${serviceType.toUpperCase()}`)}
            </button>
          )}
        </div>
      ) : null}
    </>
  );
};
