import moment from 'moment';
import { utcToLocalDaySelection } from '../coachHelpers/utility';
import { coachingHoursPackageFormValueInterface } from '../coachInterfaces/packageInterface';
import { coachingHourRequestsInterface } from '../coachInterfaces/sessionInterface';

export function getRandomArbitrary(min: number, max: number) {
  return Math.round(Math.random() * (max - min) + min);
}

export const randomFontSize = (captcha: any) => {
  const randomfontSizeString = [...captcha.toString()]
    .map((c) =>
      Math.random() < 0.6 ? c : c.fontsize(getRandomArbitrary(3, 6))
    )
    .join('');
  return {
    __html: randomfontSizeString,
  };
};
export const timeArrayGenerator = () => {
  var startTime = moment('01:00', 'HH:mm');
  var endTime = moment('12:50', 'HH:mm');

  if (endTime.isBefore(startTime)) {
    endTime.add(1, 'day');
  }

  var timeStops = [];

  while (startTime <= endTime) {
    timeStops.push(moment(startTime).locale('en').format('HH:mm'));
    startTime.add(30, 'minutes');
  }
  var dropdownData = timeStops.map((item) => {
    return {
      value: item,
      label: item,
    };
  });
  return dropdownData;
};

export const timeArrayGenerator24Format = (limit: number) => {
  var x = 30; //minutes interval
  var times = []; // time array
  var tt = 0; // start time

  //loop to increment the time and push results in array
  for (var i = 0; tt < limit * 60; i++) {
    var hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
    var mm = tt % 60; // getting minutes of the hour in 0-55 format
    times[i] = ('0' + (hh % 24)).slice(-2) + ':' + ('0' + mm).slice(-2); // pushing data in array in [00:00 - 12:00 AM/PM format]
    tt = tt + x;
  }
  var dropdownData = times.map((item) => {
    return {
      value: item,
      label: item,
    };
  });

  return dropdownData;
};
export const getTimeGreater = (start: any, end: any) => {
  var beginningTime = moment(start, 'HH:mm');
  var endTime = moment(end, 'HH:mm');
  return beginningTime.isBefore(endTime);
};
export const get12FormatTimeGreater = (start: string, end: string) => {

  const beginningTime = moment(start, 'hh:mm A');
  const endTime = moment(end, 'hh:mm A');

  return beginningTime.isBefore(endTime);
};
export const getCoachingHoursValidation = (
  coachingHoursFormValue: coachingHoursPackageFormValueInterface,
  gap: number
) => {
  let isValid = false;
  const getCoachingHoursWeekValidation = (
    array: coachingHourRequestsInterface[]
  ) => {
    let validationArray: boolean[] = [];
    array.map((item: coachingHourRequestsInterface) => {
      validationArray.push(item.day ? true : false);
    });

    return validationArray.includes(true);
  };
  const get12HourTimeValidation = (array: coachingHourRequestsInterface[]) => {
    let validationArray: boolean[] = [];
    array.map((item: coachingHourRequestsInterface) => {
      if (item.day) {
        validationArray.push(
          get12FormatTimeGreater(
            `${item.from} ${item.localFromTime}`,
            `${item.end} ${item.locaEndTime}`
          )
        );
      }
    });
    return !validationArray.includes(false);
  };
  const get2HourTimeValidationFor12HrFormat = (
    array: coachingHourRequestsInterface[]
  ) => {
    let validationArray: boolean[] = [];
    array.map((item: coachingHourRequestsInterface) => {
      if (item.day) {
        validationArray.push(
          checkTimeGap12Format(
            `${item.from} ${item.localFromTime}`,
            `${item.end} ${item.locaEndTime}`,
            gap
          )
        );
      }
    });
    return !validationArray.includes(false);
  };
  if (!coachingHoursFormValue?.alternative) {
    getTimeGreater(coachingHoursFormValue?.start, coachingHoursFormValue?.end)
      ? (isValid = true)
      : (isValid = false);
    checkTimeGap(coachingHoursFormValue.start, coachingHoursFormValue.end, gap)
      ? (isValid = true)
      : (isValid = false);
  } else {
    if (
      getCoachingHoursWeekValidation(
        coachingHoursFormValue?.coachingHourRequests
      )
    ) {
      get12HourTimeValidation(coachingHoursFormValue?.coachingHourRequests)
        ? (isValid = true)
        : (isValid = false);
      get2HourTimeValidationFor12HrFormat(
        coachingHoursFormValue?.coachingHourRequests
      )
        ? (isValid = true)
        : (isValid = false);
    } else {
      isValid = false;
    }
  }

  return isValid;
};

export function checkTimeGap(start: string, end: string, gap: any) {
  const startTime = moment(start, 'HH:mm');
  const endTime = moment(end, 'HH:mm');
  const duration = moment.duration(endTime.diff(startTime));
  const hoursGap = duration.asMinutes();
  if (hoursGap >= parseInt(gap)) {
    return true;
  } else {
    return false;
  }
}
export function checkTimeGap12Format(start: string, end: string, gap: any) {
  const startTime = moment(start, 'hh:mm A');
  const endTime = moment(end, 'hh:mm A');

  const duration = moment.duration(endTime.diff(startTime));
  const hoursGap = duration.asMinutes();
  if (hoursGap >= parseInt(gap)) {
    return true;
  } else {
    return false;
  }
}
export const getCoachingHoursPackageValidation = (
  coachingHoursFormValue: coachingHoursPackageFormValueInterface
) => {
  let isValid = false;
  const getCoachingHoursWeekValidation = (
    array: coachingHourRequestsInterface[]
  ) => {
    let validationArray: boolean[] = [];
    array.map((item: coachingHourRequestsInterface) => {
      validationArray.push(item.day ? true : false);
    });

    return validationArray.includes(true);
  };
  const get12HourTimeValidation = (array: coachingHourRequestsInterface[]) => {
    let validationArray: boolean[] = [];
    array.map((item: coachingHourRequestsInterface) => {
      if (item.day !== '') {
        validationArray.push(
          get12FormatTimeGreater(
            `${item.from} ${item.localFromTime}`,
            `${item.end} ${item.locaEndTime}`
          )
        );
      }
    });
    return !validationArray.includes(false);
  };
  if (!coachingHoursFormValue?.alternative) {
    getTimeGreater(coachingHoursFormValue?.start, coachingHoursFormValue?.end)
      ? (isValid = true)
      : (isValid = false);
  } else {
    if (
      getCoachingHoursWeekValidation(
        coachingHoursFormValue?.coachingHourRequests
      )
    ) {
      get12HourTimeValidation(coachingHoursFormValue?.coachingHourRequests)
        ? (isValid = true)
        : (isValid = false);
    } else {
      isValid = false;
    }
  }

  return isValid;
};
export const getDefaultValueHours = (
  value: any,
  options: any,
  startTime: any
) => {
  return options?.filter((item: any) => {
    return (
      item.value.toUpperCase() === utcToLocalDaySelection(value, startTime)
    );
  });
};
export const getDefaultValue = (value: any, options: any) => {
  return options?.filter((item: any) => item.value === value);
};
export const getDefaultValuePhoneCode = (value: any, options: any) => {
  return options?.filter((item: any) => item.dial_code === value);
};
export const getDefaultValueArabic = (value: any, options: any) => {
  return options?.filter((item: any) => item.value === value)[0]?.label2;
};
export function checkTimeGapMin2(start: string, end: string) {
  const startTime = moment(start, 'HH:mm');
  const endTime = moment(end, 'HH:mm');

  const duration = moment.duration(endTime.diff(startTime));
  const hoursGap = duration.asMinutes();

  if (hoursGap >= 30) {
    return true;
  } else {
    return false;
  }
}
export function checkTimeGap12FormatMin2(start: string, end: string) {
  const startTime = moment(start, 'hh:mm A');
  const endTime = moment(end, 'hh:mm A');

  const duration = moment.duration(endTime.diff(startTime));
  const hoursGap = duration.asMinutes();

  if (hoursGap >= 30) {
    return true;
  } else {
    return false;
  }
}

export const getCoachingHoursValidationMin2 = (coachingHoursFormValue: any) => {
  let isValid = false;
  const getCoachingHoursWeekValidation = (
    array: coachingHourRequestsInterface[]
  ) => {
    let validationArray: boolean[] = [];
    array.map((item: coachingHourRequestsInterface) => {
      validationArray.push(item.day ? true : false);
    });

    return validationArray.includes(true);
  };
  const get12HourTimeValidation = (array: coachingHourRequestsInterface[]) => {
    let validationArray: boolean[] = [];
    array.map((item: coachingHourRequestsInterface) => {
      if (item.day) {
        validationArray.push(
          get12FormatTimeGreater(
            `${item.from} ${item.localFromTime}`,
            `${item.end} ${item.locaEndTime}`
          )
        );
      }
    });
    return !validationArray.includes(false);
  };
  const get2HourTimeValidationFor12HrFormat = (
    array: coachingHourRequestsInterface[]
  ) => {
    let validationArray: boolean[] = [];
    array.map((item: coachingHourRequestsInterface) => {
      if (item.day) {
        validationArray.push(
          checkTimeGap12FormatMin2(
            `${item.from} ${item.localFromTime}`,
            `${item.end} ${item.locaEndTime}`
          )
        );
      }
    });
    return !validationArray.includes(false);
  };
  if (!coachingHoursFormValue?.alternative) {
    getTimeGreater(coachingHoursFormValue?.start, coachingHoursFormValue?.end)
      ? (isValid = true)
      : (isValid = false);
    checkTimeGapMin2(coachingHoursFormValue.start, coachingHoursFormValue.end)
      ? (isValid = true)
      : (isValid = false);
  } else {
    if (
      getCoachingHoursWeekValidation(
        coachingHoursFormValue?.coachingHourAndServiceRequests
      )
    ) {
      get12HourTimeValidation(
        coachingHoursFormValue?.coachingHourAndServiceRequests
      )
        ? (isValid = true)
        : (isValid = false);
      get2HourTimeValidationFor12HrFormat(
        coachingHoursFormValue?.coachingHourAndServiceRequests
      )
        ? (isValid = true)
        : (isValid = false);
    } else {
      isValid = false;
    }
  }

  return isValid;
};
